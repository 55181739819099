import { Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  checkIfRegistrationCodeIsValid,
  register,
} from "../../features/registration/registrationSlice";
import { useQuery } from "../../utils";
import { history } from "../..";
import { Link } from "react-router-dom";

interface Props {}

const phoneRegExp = /^(\s*)\+?(\s*)[1-9]((\s*)\d){1,14}(\s*)$/;
const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  repeatedPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must be the same")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  privacyPolicy: Yup.boolean().oneOf([true], "Required"),
  phoneNumber: Yup.string()
    .matches(
      phoneRegExp,
      "Phone number is not valid. Example number: +48 512 109 109"
    )
    .required("Required"),
});

export const RegistrationForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const {
    registrationCode: code,
    error,
    formData,
  } = useAppSelector((state) => state.registration);

  useEffect(() => {
    if (code) return;
    const registrationCode = query.get("code");
    if (registrationCode !== null) {
      dispatch(checkIfRegistrationCodeIsValid(registrationCode));
    } else {
      history.push("/login");
    }
  }, [dispatch, query, code]);

  return (
    <>
      <h2 className="text-center text-xl mb-6 mt-10 sm:mt-10">Registration</h2>
      <Formik
        initialValues={{
          email: formData?.email || "",
          password: formData?.password || "",
          repeatedPassword: "",
          phoneNumber: formData?.phoneNumber || "",
          privacyPolicy: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          let phoneNumber = values.phoneNumber.trim().split(" ").join("");
          if (phoneNumber[0] !== "+") phoneNumber = "+" + phoneNumber;

          dispatch(
            register({
              email: values.email.trim(),
              password: values.password,
              phoneNumber,
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="flex justify-center">
            <form className="flex flex-col px-4" onSubmit={handleSubmit}>
              <input
                className="border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray"
                type="email"
                name="email"
                placeholder="e-mail"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <div className="text-red -my-3 leading-6 text-sm">
                  {errors.email}
                </div>
              )}
              <input
                className="border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray"
                type="password"
                name="password"
                placeholder="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.password && errors.password && (
                <div className="text-red -my-3 leading-6 text-sm">
                  {errors.password}
                </div>
              )}
              <input
                className="border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray"
                type="password"
                name="repeatedPassword"
                placeholder="repeat the password"
                value={values.repeatedPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.repeatedPassword && errors.repeatedPassword && (
                <div className="text-red -my-3 leading-6 text-sm">
                  {errors.repeatedPassword}
                </div>
              )}
              <input
                className="border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray"
                type="text"
                name="phoneNumber"
                placeholder="phone number"
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="text-gray -mt-3 mb-2 leading-6 text-sm">
                Phone number starts with '+'
              </div>
              {touched.phoneNumber && errors.phoneNumber && (
                <div className="text-red -my-3 leading-6 text-sm">
                  {errors.phoneNumber}
                </div>
              )}
              <label className="select-none text-gray my-4">
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mr-2 no-shadow text-blue"
                />
                I have read and agree to the{" "}
                <Link to="/privacy-policy">
                  <span className="underline hover:text-gray-light cursor-pointer">
                    privacy policy
                  </span>
                </Link>
                .
              </label>
              {touched.privacyPolicy && errors.privacyPolicy && (
                <div className="text-red -my-3 leading-6 text-sm">
                  {errors.privacyPolicy}
                </div>
              )}
              {error !== null && (
                <div className="text-red text-sm mt-3 -mb-3">{error}</div>
              )}
              <button
                className="bg-gray text-white py-3 my-6 hover:bg-gray-hover ripple-bg-gray-hover select-none"
                type="submit"
              >
                {/* Sign up */}
                Send verification SMS
              </button>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
};
