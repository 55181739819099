import React from 'react';
import { history } from '../..';

interface Props {}

export const SignUpThanks: React.FC<Props> = () => {
  return (
    <div className='flex flex-col items-center'>
      <h2 className='text-center text-xl mb-6 mt-40'>Thanks for signing up!</h2>
      <button
        onClick={() => history.push('/login')}
        className='bg-gray text-white py-3 my-16 hover:bg-gray-hover ripple-bg-gray-hover select-none w-full max-w-xs'
      >
        Go to the login page
      </button>
    </div>
  );
};
