import axios from 'axios';
import { AnnouncementI } from './announcementsSlice';

class AnnouncementsApi {
  getAnnouncements() {
    return axios
      .get<{ announcements: AnnouncementI[] }>('/announcements')
      .then((res) => res.data);
  }
}

export default new AnnouncementsApi();
