import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import announcementsReducer from '../features/announcements/announcementsSlice';
import authReducer from '../features/auth/authSlice';
import playersReducer from '../features/players/playersSlice';
import registrationReducer from '../features/registration/registrationSlice';

const reducers = combineReducers({
  auth: authReducer,
  registration: registrationReducer,
  players: playersReducer,
  announcements: announcementsReducer,
});

const rootReducer: Reducer = (
  state: RootState,
  action: AnyAction
): RootState => {
  if (action.type === 'auth/logout') {
    state = {} as RootState;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducers>;
