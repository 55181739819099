import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  register,
  setStage,
  verifyToken,
} from "../../features/registration/registrationSlice";

interface Props {}

export const SMSVerification: React.FC<Props> = () => {
  const [smsCode, setSmsCode] = useState("");
  const dispatch = useAppDispatch();
  const { verifyError, formData } = useAppSelector(
    (state) => state.registration
  );

  return (
    <>
      <h2 className="text-center text-xl mb-6 mt-10 sm:mt-10">
        Phone Number Verification
      </h2>
      <div className="flex justify-center">
        <div className="flex flex-col px-4 max-w-sm w-full">
          <input
            className="text-center text-2xl border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray outline-none"
            placeholder="6 digits code"
            value={smsCode}
            onChange={(e) => setSmsCode(e.target.value)}
            type="text"
          />
          <div className="flex justify-between mt-12">
            <button
              className="hover:text-gray-hover"
              onClick={() => dispatch(setStage("form"))}
            >
              Go back
            </button>
            <button
              className="hover:text-gray-hover"
              onClick={() => {
                if (formData)
                  dispatch(
                    register({
                      email: formData.email,
                      password: formData.password,
                      phoneNumber: formData.phoneNumber,
                    })
                  );
              }}
            >
              Send sms again
            </button>
          </div>
          {verifyError !== null && (
            <div className="text-red text-sm mt-3 -mb-3">{verifyError}</div>
          )}
          <button
            className="bg-gray text-white py-3 my-3 hover:bg-gray-hover ripple-bg-gray-hover select-none"
            type="submit"
            onClick={() => dispatch(verifyToken(smsCode))}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
