import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { history } from "../..";
import { RootState } from "../../app/store";
import registrationApi from "./registrationApi";

export interface FormData {
  email: string;
  password: string;
  phoneNumber: string;
}

type Stage = "form" | "verification" | "thanks";

interface RegistrationState {
  stage: Stage;
  error: string | null;
  verifyError: string | null;
  isCodeValid: boolean | null;
  registrationCode: string | null;
  team: {
    name: string;
    arm: string;
  } | null;
  formData: FormData | null;
}

const initialState: RegistrationState = {
  stage: "form",
  error: null,
  verifyError: null,
  isCodeValid: null,
  registrationCode: null,
  team: null,
  formData: null,
};

export const checkIfRegistrationCodeIsValid = createAsyncThunk(
  "/registration/code/",
  async (registrationCode: string, thunkApi) => {
    const response = await registrationApi.checkIfRegistrationCodeIsValid(
      registrationCode
    );
    if (response.isValid === false) {
      history.push("/login");
    }
    return response;
  }
);

export const register = createAsyncThunk(
  "/registration/register",
  async (formData: FormData, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      return await registrationApi.register(
        formData,
        state.registration.registrationCode || ""
      );
    } catch (err: any) {
      const errMessage = err.response.data.error || "Error";
      return rejectWithValue(errMessage);
    }
  }
);

export const verifyToken = createAsyncThunk(
  "/registration/verifyToken",
  async (token: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      return await registrationApi.verifyToken(
        token,
        state.registration.formData?.phoneNumber || ""
      );
    } catch (err: any) {
      const errMessage = err.response.data.error || "Error";
      return rejectWithValue(errMessage);
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setStage(state, action: PayloadAction<Stage>) {
      state.stage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIfRegistrationCodeIsValid.pending, (state) => {
      state.error = null;
    });

    builder.addCase(
      checkIfRegistrationCodeIsValid.fulfilled,
      (state, action) => {
        state.isCodeValid = action.payload.isValid;
        if (action.payload.isValid === true) {
          state.registrationCode = action.meta.arg;
          state.team = action.payload.team;
        }
      }
    );

    builder.addCase(
      checkIfRegistrationCodeIsValid.rejected,
      (state, action) => {
        state.error = "Error occurred. Try again later or contact us.";
        history.push("/login");
      }
    );

    builder.addCase(register.pending, (state) => {
      state.error = null;
    });

    builder.addCase(register.fulfilled, (state, action) => {
      state.stage = "verification";
      state.formData = action.meta.arg;
    });

    builder.addCase(register.rejected, (state, action) => {
      state.error = action.payload as string;
    });

    builder.addCase(verifyToken.pending, (state) => {
      state.verifyError = null;
    });

    builder.addCase(verifyToken.fulfilled, (state) => {
      state.stage = "thanks";
    });

    builder.addCase(verifyToken.rejected, (state, action) => {
      state.verifyError = action.payload as string;
    });
  },
});

export const { setStage } = registrationSlice.actions;
export default registrationSlice.reducer;
