import React, { useState } from "react";
import Logo from "../assets/imgs/logo.png";
import Cup from "../assets/imgs/puchar.png";
import Facebook from "../assets/imgs/facebook.png";
import Instagram from "../assets/imgs/instagram.png";
import Twitter from "../assets/imgs/twitter.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { history } from "..";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logout } from "../features/auth/authSlice";

interface Props {
  userLogo?: string;
  userName?: string;
  showMenuIcon?: boolean;
}

export const MainLayout: React.FC<Props> = ({
  children,
  userLogo,
  userName,
  showMenuIcon = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      <div className="flex flex-col items-stretch relative mx-auto min-h-screen">
        {/* Header */}
        <div className="absolute top-0 left-0 top-bar h-28 w-full bg-[url('../public/assets/backgrounds/blue-pattern.png')]"></div>
        <div className="flex items-center justify-between relative z-10 h-28">
          <img className="w-16 ml-[10%]" src={Logo} alt="" />
          {userLogo && userName && (
            <div className="flex flex-col items-center uppercase absolute bottom-[calc(25.91%_+_20px)] left-1/2 -translate-x-1/2 translate-y-full sm:static sm:translate-x-0 sm:translate-y-0">
              <img className="w-10 mb-1" src={userLogo} alt="" />
              <h2 className="text-center sm:text-white">{userName}</h2>
            </div>
          )}
          {showMenuIcon && (
            <div
              onClick={() => setShowMenu((prev) => !prev)}
              className="w-14 mr-[10%] flex self-start sm:self-auto mt-3 sm:mt-0 justify-center cursor-pointer text-white hover:text-gray-light"
            >
              <FontAwesomeIcon icon={showMenu ? faTimes : faBars} size={"2x"} />
            </div>
          )}
          {showMenuIcon === false && (
            <img className="w-14 mr-[10%]" src={Cup} alt="" />
          )}
        </div>

        {/* Content */}
        <div className="flex-1">{children}</div>

        {/* Footer */}
        <div className="h-28 flex flex-col justify-end">
          <div className="flex relative z-10 text-white text-xs mb-2">
            <div className="flex-1"></div>
            <div>
              <a
                href="https://fundacjamalimistrzowie.pl/"
                target="_blank"
                rel="noreferrer"
              >
                www.fundacjamalimistrzowie.pl
              </a>
            </div>
            <div className="flex-1"></div>
          </div>
          <div className="relative z-10 flex self-center justify-between w-44 mb-3">
            <a
              href="https://www.facebook.com/fundacjamalimistrzowie"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src={Facebook} alt="" />
            </a>
            <a
              href="https://www.instagram.com/biuro.malimistrzowie/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src={Instagram} alt="" />
            </a>
            <a
              href="https://twitter.com/fmalimistrzowie"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src={Twitter} alt="" />
            </a>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 bottom-bar h-28 w-full bg-[url('../public/assets/backgrounds/red-pattern.png')]"></div>
      </div>
      {showMenu && (
        <div className="absolute top-28 left-0 w-full min-h-[calc(100%_-_7rem)] bg-[rgba(255,_255,_255,_0.9)] z-50 animate-fade-in">
          <div className="flex flex-col items-center px-8 py-8">
            <button
              onClick={() => {
                history.push("/news");
                setShowMenu(false);
              }}
              className="bg-gray text-white py-2 my-4 hover:bg-gray-hover ripple-bg-gray-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              NEWS
            </button>
            <button
              onClick={() => {
                history.push("/agenda");
                setShowMenu(false);
              }}
              className="bg-gray text-white py-2 my-4 hover:bg-gray-hover ripple-bg-gray-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              AGENDA
            </button>
            <button
              onClick={() => {
                history.push("/team");
                setShowMenu(false);
              }}
              className="bg-gray text-white py-2 my-4 hover:bg-gray-hover ripple-bg-gray-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              TEAM
            </button>

            <button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_ASSETS_API_URL}${
                    user?.tourney_id
                  }?team-id=${user?.team_id || ""}`,
                  "_blank"
                );
                setShowMenu(false);
              }}
              className="bg-blue text-white py-4 my-4 hover:bg-blue-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              <div className="">{user?.tourney_name}</div>
              <div className="font-bold text-lg">GROUPS / MATCHES</div>
            </button>

            <button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_ASSETS_API_URL}${
                    user?.tourney_id
                  }?team-id=${user?.team_id || ""}/#scoreboards-1`,
                  "_blank"
                );
                setShowMenu(false);
              }}
              className="bg-blue text-white py-4 my-4 hover:bg-blue-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              <div className="">{user?.tourney_name}</div>
              <div className="font-bold text-lg">SCOREBOARDS</div>
            </button>

            <button
              onClick={() => {
                dispatch(logout());
                setShowMenu(false);
              }}
              className="bg-gray text-white py-2 my-4 hover:bg-gray-hover ripple-bg-gray-hover select-none w-full max-w-xs mx-3"
              type="submit"
            >
              LOGOUT
            </button>
          </div>
        </div>
      )}
    </>
  );
};
