import {
  PlayerI,
  fetchPlayers,
  updatePlayers,
} from "../features/players/playersSlice";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { PlayerListItem } from "../components/spec/PlayerListItem";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {}

export const ManageTeam: React.FC<Props> = () => {
  const { entries, update } = useAppSelector((state) => state.players);
  const [players, setPlayers] = useState<PlayerI[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setPlayers([
      ...entries,
      {
        id: null,
        full_name: "",
        first_day_number: null,
        second_day_number: null,
        user_id: null,
      },
    ]);
  }, [entries]);

  useEffect(() => {
    dispatch(fetchPlayers());
  }, [dispatch]);

  const handleInputChange = (oldPlayer: PlayerI, newPlayer: PlayerI) => {
    const newRowNeeded = oldPlayer === players[players.length - 1];

    const newPlayers = players.map((p) => {
      if (p === oldPlayer) return newPlayer;
      else return p;
    });

    if (newRowNeeded)
      newPlayers.push({
        id: null,
        full_name: "",
        first_day_number: null,
        second_day_number: null,
        user_id: null,
      });
    setPlayers(newPlayers);
  };

  const handleSavePlayers = () => {
    const playersWithData = players.filter(
      (player) =>
        player.full_name !== "" ||
        player.first_day_number !== null ||
        player.second_day_number !== null
    );
    const toAdd = playersWithData.filter((p) => p.id === null);
    const toUpdate = playersWithData.filter((p) => p.id !== null);
    const toDelete = players.filter(
      (p) =>
        p.id !== null &&
        p.first_day_number === null &&
        p.second_day_number === null &&
        p.full_name === ""
    );
    dispatch(
      updatePlayers({
        toAdd,
        toUpdate,
        toDelete,
      })
    );
    // console.log(readyToSavePlayers);
  };

  return (
    <>
      <Helmet>
        <title>Manage Team - SALTmineCUP</title>
      </Helmet>
      <h2 className="text-center text-xl mb-6 mt-10 sm:mt-10">Team</h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-[1fr_60px_60px] sm:grid-cols-[1fr_100px_100px] border mx-1 sm:mx-5 text-center text-sm max-w-xl w-full">
          <div>Name</div>
          <div className="col-span-2 px-2">T-shirt number</div>
          <div className="bg-gray"></div>
          <div className="bg-gray col-span-2 text-white text-xs py-0.5">
            1 - day - 2
          </div>
          {players.map((player, i) => (
            <PlayerListItem
              key={i}
              player={player}
              setPlayer={handleInputChange}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center h-24 my-10">
        {update.isLoading ? (
          <div className="text-blue text-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="animate-spin text-3xl"
            />
          </div>
        ) : update.isSuccess ? (
          <div className="text-success">Saved successfully</div>
        ) : (
          <button
            onClick={handleSavePlayers}
            className="bg-blue text-white py-3 px-16 sm:px-20 hover:bg-blue-hover ripple-bg-blue-hover select-none mx-auto"
            type="submit"
          >
            Save
          </button>
        )}
      </div>
    </>
  );
};
