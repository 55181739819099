import { Formik } from 'formik';
import React from 'react';
import { MainLayout } from '../layouts/MainLayout';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logIn } from '../features/auth/authSlice';
import { Helmet } from 'react-helmet';

interface Props {}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

export const Login: React.FC<Props> = () => {
  const { error } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  return (
    <MainLayout>
      <Helmet>
        <title>Login - SALTmineCUP</title>
      </Helmet>
      <h2 className='text-center text-xl mb-6 mt-10 sm:mt-10'>Login</h2>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={({ email, password }, { setSubmitting }) => {
          dispatch(logIn({ email, password }));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className='flex justify-center'>
            <form
              className='flex flex-col px-4 max-w-sm w-full'
              onSubmit={handleSubmit}
            >
              <input
                className='border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray'
                type='email'
                name='email'
                placeholder='e-mail'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <div className='text-red -my-3 leading-6 text-sm'>
                  {errors.email}
                </div>
              )}
              <input
                className='border-0 border-b border-gray-light text-gray w-full p-1 my-6 no-shadow focus:border-gray'
                type='password'
                name='password'
                placeholder='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.password && errors.password && (
                <div className='text-red -my-3 leading-6 text-sm'>
                  {errors.password}
                </div>
              )}
              {error !== null && (
                <div className='text-red text-sm mt-3 -mb-3'>{error}</div>
              )}
              <button
                className='bg-gray text-white py-3 my-16 hover:bg-gray-hover ripple-bg-gray-hover select-none'
                type='submit'
              >
                Sign in
              </button>
            </form>
          </div>
        )}
      </Formik>
    </MainLayout>
  );
};
