import axios from 'axios';
import { UserI } from './authSlice';

class AuthApi {
  logIn(email: string, password: string) {
    return axios
      .post<{ token: string; team: UserI }>('/log-in', {
        email,
        password,
      })
      .then((res) => res.data);
  }
}

export default new AuthApi();
