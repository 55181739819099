import axios from 'axios';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAppSelector } from './app/hooks';
import { ForGuests } from './components/routes/ForGuests';
import { ForLoggedIn } from './components/routes/ForLoggedIn';
import { MainLayout } from './layouts/MainLayout';
import { Agenda } from './pages/Agenda';
import { Login } from './pages/Login';
import { ManageTeam } from './pages/ManageTeam';
import { News } from './pages/News';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Registration } from './pages/Registration/Registration';

function App() {
  const { user, token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (token !== null)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    else delete axios.defaults.headers.common['Authorization'];
  }, [token]);

  return (
    <div>
      <Switch>
        <ForGuests path='/login'>
          <Login />
        </ForGuests>
        <ForLoggedIn path={['/news', '/team', '/agenda']}>
          <MainLayout
            userLogo={`${process.env.REACT_APP_ASSETS_API_URL}/assets/arms/${user?.arm_name}`}
            userName={user?.name}
            showMenuIcon={true}
          >
            <Route path='/news' component={News} />
            <Route path='/team' component={ManageTeam} />
            <Route path='/agenda' component={Agenda} />
          </MainLayout>
        </ForLoggedIn>
        <ForGuests path='/registration'>
          <Registration />
        </ForGuests>
        <ForGuests path='/privacy-policy'>
          <PrivacyPolicy />
        </ForGuests>
        <Route path='*'>
          <Redirect to='/login' />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
