import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../..';
import { RootState } from '../../app/store';
import registrationApi from '../registration/registrationApi';
import announcementsApi from './announcementsApi';

export interface AnnouncementI {
  id: number;
  content: string;
  update_date: string;
  is_agenda: boolean;
}

interface AnnouncementsState {
  entries: AnnouncementI[];
}

const initialState: AnnouncementsState = {
  entries: [],
};

export const fetchAnnouncements = createAsyncThunk(
  '/announcements',
  async () => {
    return await announcementsApi.getAnnouncements();
  }
);

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncements.fulfilled, (state, action) => {
      state.entries = action.payload.announcements;
    });
  },
});

export const {} = announcementsSlice.actions;
export default announcementsSlice.reducer;
