import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

interface Props {
  path: string | string[];
}

export const ForLoggedIn: React.FC<Props> = ({ children, path }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return (
    <Route path={path}>
      {isAuthenticated === true ? <>{children}</> : <Redirect to='/login' />}
    </Route>
  );
};
