import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authApi from "./authApi";

export interface UserI {
  id: number;
  email: string;
  password: string;
  is_confirmed: boolean;
  registration_code: string;
  phone_number: string;
  arm_name: string;
  name: string;
  tourney_id: number;
  tourney_name: string;
  team_id: number;
}

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  error: string | null;
  isLoading: boolean;
  user: UserI | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  error: null,
  isLoading: false,
  user: null,
};

export const logIn = createAsyncThunk(
  "/auth/logIn",
  async (args: { email: string; password: string }, thunkApi) => {
    try {
      const payload = await authApi.logIn(args.email, args.password);
      return payload;
    } catch (err: any) {
      const errMessage = err.response.data.error || "Error";
      return thunkApi.rejectWithValue(errMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout() {},
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(logIn.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.team;
      state.isAuthenticated = true;
      state.isLoading = false;
    });

    builder.addCase(logIn.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
