import { PlayerI } from "../../features/players/playersSlice";
import React from "react";
import { useAppSelector } from "../../app/hooks";

interface Props {
  player: PlayerI;
  setPlayer: (oldPlayer: PlayerI, newPlayer: PlayerI) => void;
}

export const PlayerListItem: React.FC<Props> = ({ player, setPlayer }) => {
  const { isLoading } = useAppSelector((state) => state.players.fetch);
  return (
    <>
      <div className="flex items-center justify-center m-1">
        <input
          type="text"
          disabled={isLoading}
          value={player.full_name || ""}
          onChange={(e) =>
            setPlayer(player, { ...player, full_name: e.target.value })
          }
          className="w-full p-1 text-sm sm:text-base"
        />
      </div>
      <div className="flex items-center justify-center m-1">
        <input
          type="text"
          disabled={isLoading}
          value={
            player.first_day_number === null ? "" : player.first_day_number
          }
          onChange={(e) => {
            const isNumber = Number.isNaN(Number(e.target.value)) === false;
            setPlayer(player, {
              ...player,
              first_day_number:
                e.target.value === ""
                  ? null
                  : isNumber === false
                  ? player.first_day_number
                  : Number(e.target.value),
            });
          }}
          className="w-full p-1 text-sm sm:text-base"
        />
      </div>
      <div className="flex items-center justify-center m-1">
        <input
          type="text"
          disabled={isLoading}
          value={
            player.second_day_number === null ? "" : player.second_day_number
          }
          onChange={(e) => {
            const isNumber = Number.isNaN(Number(e.target.value)) === false;
            setPlayer(player, {
              ...player,
              second_day_number:
                e.target.value === ""
                  ? null
                  : isNumber === false
                  ? player.second_day_number
                  : Number(e.target.value),
            });
          }}
          className="w-full p-1 text-sm sm:text-base"
        />
      </div>
    </>
  );
};
