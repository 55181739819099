import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import TeamLogo from "../../assets/imgs/arms/Wisła Kraków.png";
import { RegistrationForm } from "./RegistrationForm";
import { SignUpThanks } from "./SignUpThanks";
import { SMSVerification } from "./SMSVerification";
import { useAppSelector } from "../../app/hooks";
import { history } from "../..";
import { Helmet } from "react-helmet";

interface Props {}

export const Registration: React.FC<Props> = () => {
  const { team, stage } = useAppSelector((state) => state.registration);
  const { pathname } = useLocation();

  useEffect(() => {
    if (stage === 'form' && pathname !== '/registration')
      history.push('/registration');
    else if (
      stage === 'verification' &&
      pathname !== '/registration/verification'
    )
      history.push('/registration/verification');
    else if (stage === 'thanks' && pathname !== '/registration/thanks')
      history.push('/registration/thanks');
    // if (stage === "form" && pathname !== "/registration")
    //   history.push("/registration");
    // else if (stage === "thanks" && pathname !== "/registration/thanks")
    //   history.push("/registration/thanks");
  }, [pathname, stage]);

  return (
    <MainLayout
      userLogo={`${process.env.REACT_APP_ASSETS_API_URL}/assets/arms/${team?.arm}`}
      userName={team ? team.name : ""}
    >
      <Helmet>
        <title>Registration - SALTmineCUP</title>
      </Helmet>
      <Switch>
        <Route path="/registration" exact>
          <RegistrationForm />
        </Route>
        <Route path="/registration/verification" component={SMSVerification} />
        <Route path="/registration/thanks" component={SignUpThanks} />
      </Switch>
    </MainLayout>
  );
};
