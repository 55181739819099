import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import playersApi from "./playersApi";

export interface PlayerI {
  id: number | null;
  full_name: string | null;
  first_day_number: number | null;
  second_day_number: number | null;
  user_id: number | null;
}

interface PlayersState {
  entries: PlayerI[];
  fetch: {
    isLoading: boolean;
    isSuccess: boolean;
  };
  update: {
    isLoading: boolean;
    isSuccess: boolean;
  };
}

const initialState: PlayersState = {
  entries: [],
  fetch: {
    isLoading: false,
    isSuccess: false,
  },
  update: {
    isLoading: false,
    isSuccess: false,
  },
};

export const fetchPlayers = createAsyncThunk("/players/list", async () => {
  return await playersApi.fetchPlayers();
});

export const updatePlayers = createAsyncThunk(
  "/players/update",
  async (
    playersDto: { toAdd: PlayerI[]; toUpdate: PlayerI[]; toDelete: PlayerI[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await playersApi.updatePlayers(playersDto);
      dispatch(fetchPlayers());
      setTimeout(() => {
        dispatch(setUpdateSuccess(false));
      }, 2000);
      return response;
    } catch (err) {
      rejectWithValue(null);
    }
  }
);

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    setUpdateSuccess(state, action: PayloadAction<boolean>) {
      state.update.isSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlayers.pending, (state, action) => {
      state.fetch.isLoading = true;
      state.fetch.isSuccess = false;
    });

    builder.addCase(fetchPlayers.rejected, (state, action) => {
      state.fetch.isLoading = false;
    });

    builder.addCase(fetchPlayers.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isSuccess = true;
      state.entries = action.payload.players;
    });

    builder.addCase(updatePlayers.pending, (state, action) => {
      state.update.isLoading = true;
    });

    builder.addCase(updatePlayers.fulfilled, (state, action) => {
      state.update.isLoading = false;
      state.update.isSuccess = true;
    });

    builder.addCase(updatePlayers.rejected, (state, action) => {
      state.update.isLoading = false;
    });
  },
});

export const { setUpdateSuccess } = playersSlice.actions;
export default playersSlice.reducer;
