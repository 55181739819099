import axios from 'axios';
import { FormData } from './registrationSlice';

class RegistrationApi {
  checkIfRegistrationCodeIsValid(registrationCode: string) {
    return axios
      .post<{ isValid: boolean; team: { name: string; arm: string } }>(
        '/sign-up/code',
        {
          registrationCode,
        }
      )
      .then((res) => res.data);
  }

  register(formData: FormData, registrationCode: string) {
    return axios
      .post('/sign-up', {
        ...formData,
        registrationCode,
      })
      .then((res) => res.data);
  }

  verifyToken(token: string, phoneNumber: string) {
    return axios
      .post('/sign-up/verify', {
        token,
        phoneNumber,
      })
      .then((res) => res.data);
  }
}

export default new RegistrationApi();
