import { differenceInHours, format } from 'date-fns';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchAnnouncements } from '../features/announcements/announcementsSlice';

interface Props {}

export const News: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { announcements } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>News - SALTmineCUP</title>
      </Helmet>
      <h2 className='text-center text-xl mb-6 mt-10 sm:mt-10'>News</h2>

      <div className='flex flex-col items-center px-3'>
        {announcements.entries.map((announcement) => {
          const showTime =
            differenceInHours(new Date(), new Date(announcement.update_date), {
              roundingMethod: 'ceil',
            }) <= 24;
          return (
            <div className='w-full max-w-2xl' key={announcement.id}>
              <div className='text-right mt-5'>
                {format(
                  new Date(announcement.update_date),
                  showTime ? 'HH:mm' : 'do MMM'
                )}
              </div>
              <div
                className='content border-t py-3 my-1 px-3 mb-16 border-gray-light'
                dangerouslySetInnerHTML={{ __html: announcement.content }}
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
};
