import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

interface Props {
  path: string;
}

export const ForGuests: React.FC<Props> = ({ path, children }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return (
    <>
      <Route path={path}>
        {isAuthenticated === true ? <Redirect to='/news' /> : <>{children}</>}
      </Route>
    </>
  );
};
