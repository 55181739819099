import { PlayerI } from "./playersSlice";
import axios from "axios";

class PlayersApi {
  fetchPlayers() {
    return axios
      .get<{ players: PlayerI[] }>("/players")
      .then((res) => res.data);
  }

  updatePlayers(playersDto: {
    toAdd: PlayerI[];
    toUpdate: PlayerI[];
    toDelete: PlayerI[];
  }) {
    return axios.put("/players", playersDto).then((res) => res.data);
  }
}

export default new PlayersApi();
